import {useEffect, useRef} from 'react'

interface InnerHtmlProps {
  html: string
}
const InnerHtml = (props: InnerHtmlProps) => {
  const { html } = props
  const divRef = useRef<HTMLDivElement|null>(null)

  useEffect(() => {
    if (!html || !divRef.current) return

    const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = '' // Clear the container
    divRef.current.appendChild(slotHtml) // Append the new content
  }, [html])


  return (
    <div ref={divRef}/>
  )
}

export default InnerHtml
